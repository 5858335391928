import React from 'react'
import { SiteHeader as Component } from '@swp/components'
import items from './ItemSets'

const languages = []

export default function SiteHeader({ set = '', currentLanguage }) {
return (
  <Component className="pos--fix --spacing3 --size-S" type={"SiteHeader"} fixed={true} layout={"1"} transparent={false} langaugeSelector={true} layoutMobile={"1"} social={{"instagram":"bager_novabana?igsh=eWhiOHNzejhjaWpr","facebook":"share/p/19wxmAmyTc/","twitter":""}} logo={{"src":"https://cdn.swbpg.com/o/8469/9b9413316cc248ae9a380a5c548ab0ab.png"}} logoMobile={{"src":"https://cdn.swbpg.com/o/8469/9b9413316cc248ae9a380a5c548ab0ab.png"}} title={{"color":"rgba(255,255,255,1)","content":"ZEMNÉ, VÝKOPOVÉ PRÁCE ĽUBOSLAV MORAVČÍK","className":"ff--3 fs--36 lh--14"}} button={{"content":"O nás","blank":false,"page":"/","section":"lzycv22dqjb","className":"btn-box"}} item={{"color":"rgba(255,255,255,1)","colorHover":"rgba(250,236,95,1)","className":"ff--3 fs--18"}} items={items?.[set]?.items} languages={languages} currentLanguage={currentLanguage} />
)
}