export default {
  "": {
    "id": 16066,
    "temp_key": "",
    "site_id": 34275,
    "name": "hlavička",
    "items": [
      {
        "blank": false,
        "id": "5216bkm2zrhp",
        "name": "ÚVOD",
        "page": "/",
        "section": "informace-1",
        "use": "page",
        "elements": []
      },
      {
        "id": "t1myy5n2vmdz",
        "name": "O NÁS",
        "page": "/",
        "section": "informacie",
        "use": "page",
        "elements": []
      },
      {
        "blank": false,
        "id": "bk7quwrqtgxr",
        "name": "NAŠE SLUŽBY",
        "page": "/",
        "section": "nase_sluzby",
        "use": "page",
        "elements": []
      },
      {
        "id": "o1wbuzwdk0s2",
        "name": "PONUKA TECHNIKY",
        "page": "/",
        "section": "ponuka",
        "use": "page",
        "elements": []
      },
      {
        "id": "s9g7zhaabfuc",
        "name": "Referencie",
        "page": "/",
        "section": "referencie",
        "use": "page",
        "elements": []
      },
      {
        "blank": false,
        "id": "uew9eq581ck8",
        "name": "CENNÍK",
        "page": "/",
        "section": "cennik",
        "use": "page",
        "elements": []
      },
      {
        "id": "vzf5huqp0yj2",
        "name": "Galéria",
        "page": "/",
        "section": "galeria",
        "use": "page",
        "elements": []
      },
      {
        "id": "cfe7ef7gc3tv",
        "name": "ADRESA",
        "page": "/",
        "section": "kontakt-2",
        "use": "page",
        "elements": []
      },
      {
        "id": "qc8lgb7sh8nj",
        "name": "KONTAKT",
        "page": "/",
        "section": "fakturacneudaje",
        "use": "page",
        "elements": []
      }
    ]
  }
}